import axios from 'axios'
import authHeader from '../services/auth-header'
import store from '../store.js'

const API_URL = process.env.VUE_APP_API_URL || 'https://api.socia-lists.com'

class AuthService {
  persistUser (data) {
    localStorage.setItem('user', JSON.stringify(data))
    store.isLoggedIn = true
    store.user = {
      id: data.id,
      username: data.username,
      email: data.email,
      image: data.image
    }
  }

  login (user) {
    return axios
      .post(API_URL + '/login', {
        email: user.email,
        password: user.password
      })
      .then(response => {
        if (response.data.token) {
          this.persistUser(response.data)
        }

        return response.data
      })
      .catch(function () {
        return { error: 'Invalid credentials.' }
      })
  }

  logout () {
    localStorage.removeItem('user')
    store.isLoggedIn = false
  }

  register (user) {
    return axios
      .post(API_URL + '/register', {
        username: user.username,
        email: user.email,
        password: user.password
      })
      .then(response => {
        if (response.data.token) {
          this.persistUser(response.data)
        }

        return response.data
      })
      .catch(error => {
        if (error.response.status === 422) {
          return { errors: error.response.data }
        }
      })
  }

  forgotPassword (user) {
    return axios
      .post(API_URL + '/forgot', {
        email: user.email
      })
      .then(response => {
        return response.data
      })
      .catch(error => {
        console.log(error)
      })
  }

  resetPassword (user) {
    return axios
      .post(API_URL + '/reset', {
        password: user.password,
        token: user.token
      })
      .then(response => {
        return response.data
      })
      .catch(error => {
        console.log(error)
      })
  }

  update (user) {
    return axios
      .put(API_URL + '/profile', {
        username: user.username,
        email: user.email,
        password: user.password,
        newPassword: user.newPassword
      }, {
        headers: authHeader()
      })
      .then(response => {
        if (response.data.token) {
          this.persistUser(response.data)
        }

        return response.data
      })
      .catch(error => {
        return { status: error.response.status, errors: error.response.data }
      })
  }
}

export default new AuthService()
