export default {
  isEmpty: function (value) {
    return (!value || /^\s*$/.test(value))
  },
  isValidSlug: function (value) {
    return /^[-a-z0-9]+$/g.test(value)
  },
  isValidUsername: function (value) {
    return /^[-a-z0-9]+$/g.test(value)
  },
  isValidEmail: function (value) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
  },
  isValidPassword: function (value) {
    return value.length > 7
  }
}
