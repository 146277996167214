<template>
  <h2 class="page-title">Password Reset</h2>
  <div v-if="success" class="message message-success">
    <p>Your password has been updated.</p>
    <div><router-link class="btn" to="/login">Login</router-link></div>
  </div>
  <form v-if="!success" v-on:submit.prevent="update">
    <div class="message message-error" v-if="error">{{ error }}</div>
    <div class="form-group">
      <div class="form-input">
        <input type="password" v-model="password" placeholder="New Password" v-bind:class="[passwordError ? 'error' : '']">
        <div class="form-error" v-if="passwordError">{{ passwordError }}</div>
      </div>
    </div>
    <div class="form-input form-actions">
      <Loader v-if="isLoading" />
      <button v-if="!isLoading" class="btn" type="submit">Update Password</button>
    </div>
  </form>
</template>

<script>
import Loader from '@/components/Loader.vue'
import AuthService from '../services/auth.service'
import store from '../store.js'
import validator from '../services/validator'

export default {
  name: 'PasswordReset',
  components: {
    Loader
  },
  data () {
    return {
      success: false,
      isLoading: false,
      error: '',
      password: '',
      passwordError: ''
    }
  },
  created () {
    if (store.isLoggedIn) {
      this.$router.push('/dashboard')
    }
  },
  methods: {
    validate: function () {
      this.emailError = ''
      this.passwordError = ''
      if (validator.isEmpty(this.password)) {
        this.passwordError = 'Password is required.'
        return false
      }
      return true
    },
    update: function () {
      if (this.validate()) {
        this.isLoading = true
        AuthService
          .resetPassword({
            password: this.password,
            token: this.$route.params.token
          })
          .then(response => {
            this.isLoading = false
            this.success = true
          })
      }
    }
  }
}
</script>
